import { request, gql } from 'graphql-request'

export default async function (userId, collectionId, onepagerId, type, period, dateRange) {
  // type: timeseries / aggregate
  // period: https://plausible.io/docs/stats-api#time-periods ## 12mo, 30d
  // custom range: custom [ '2021-01-01', 'YYYY-MM-DD' ] array startDate, endDate

  // let apiURL = "";
  let pagePath = '/t/' + userId

  // if (collectionId) pagePath = pagePath + "/" + collectionId;
  if (onepagerId) pagePath = pagePath + '/' + onepagerId

  if (!onepagerId) pagePath = pagePath + '/**'

  const getPageViewsGql = gql`
    query getViews($path: String!, $type: String!, $period: String!, $dateRange: String) {
      getPageViews(path: $path, type: $type, period: $period, dateRange: $dateRange) {
        results
      }
    }
  `

  try {
    const getData = await request('https://onepager.graphcdn.app', getPageViewsGql, {
      path: pagePath,
      type: type,
      period: period,
      dateRange: dateRange ? dateRange.toString() : '',
    })

    return JSON.parse(getData.getPageViews.results)
  } catch (err) {
    return false
  }
}
