import styles from './Footer.module.css'

import { Logo } from '@onepager/ui'

const Footer = ({}) => {
  return (
    <footer className={`${styles.footer} container`}>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.linksWrapper}>
          <div className={styles.links}>
            <a target="_blank" href="https://onepager.io/privacy-policy">
              Privacy Policy
            </a>
            <a target="_blank" href="https://onepager.io/terms-conditions">
              Terms of Service
            </a>
            <a target="_blank" href="https://support.onepager.io/support/home">
              Support
            </a>
          </div>
          <p className={styles.copyright}>&copy; onepager.io</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
