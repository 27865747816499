import styles from './SubNavLink.module.css'
import { css } from '@onepager/stdlib'
import Link from 'next/link'

const NavLink = ({ path = '#', text, icon, onClick, className, active }) => {
  return (
    <Link href={path}>
      <div onClick={onClick} className={css(styles.link, className, active ? styles.active : 'bg-slate-100')}>
        {icon && <span>{icon}</span>}
        {text && <span>{text}</span>}
      </div>
    </Link>
  )
}

export default NavLink
