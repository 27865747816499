import styles from './Nav.module.css'

import { NavLink, Logo, Search } from '@onepager/ui'
import { useState } from 'react'

import {
  RiPagesLine,
  RiMenuLine,
  RiSafeLine,
  RiSettings4Line,
  RiFlashlightLine,
  RiLayout4Line,
  RiSearch2Line,
} from 'react-icons/ri'

const Navigation = ({}) => {
  const [search, setSearch] = useState(false)

  return (
    <nav className={styles.navigation}>
      <div className={`${styles.wrapper} container`}>
        <Logo />
        <div className={styles.links}>
          <NavLink path="/onepagers" icon={<RiPagesLine />} text="Onepagers" />
          <NavLink path="/collections" icon={<RiMenuLine />} text="Collections" />
          <NavLink path="/onepagers/new" icon={<RiLayout4Line />} text="Templates" />
          <NavLink path="/integrations" icon={<RiFlashlightLine />} text="Integrations" />
          <NavLink path="/vault" icon={<RiSafeLine />} text="Vault" />
          <NavLink path="/account" icon={<RiSettings4Line />} />
          <NavLink
            onClick={() => {
              setSearch(true)
            }}
            icon={<RiSearch2Line />}
          />
        </div>
      </div>
      <Search
        isVisible={search}
        close={() => {
          setSearch(false)
        }}
      />
    </nav>
  )
}

export default Navigation
