const hasOwn = {}.hasOwnProperty

export function css(...classes) {
  for (let i = 0; i < arguments.length; i++) {
    const arg = arguments[i]
    if (!arg) continue

    const argType = typeof arg

    switch (argType) {
      case 'string':
        classes.push(arg)
        break

      case 'number':
        classes.push(arg)
        break

      case 'object':
        if (arg.toString === Object.prototype.toString) {
          for (let key in arg) {
            if (hasOwn.call(arg, key) && arg[key]) {
              classes.push(key)
            }
          }
        } else {
          classes.push(arg.toString())
        }
        break

      case Array.isArray(arg):
        if (arg.length) {
          const inner = classNames.apply(null, arg)
          if (inner) {
            classes.push(inner)
          }
        }
        break
    }
  }

  return classes.join(' ')
}
