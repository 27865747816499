import { createClient } from '@supabase/supabase-js'

export const supabase = createClient(
  'https://nevsxpluvrbrmvhmyiat.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5ldnN4cGx1dnJicm12aG15aWF0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDQ0NjQxMjksImV4cCI6MTk2MDA0MDEyOX0.LIjy2IaQvveeow5KXQOFNvZfac-iiS3CJ0YtMo5g2k4'
)

// todo - check if this is ever bundle'd on client - ultimate power!
// export const superbase = createClient(
// 	"https://nevsxpluvrbrmvhmyiat.supabase.co",
// 	process.env.SUPABASE_SERVICE_KEY
// );
