import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Filler,
} from 'chart.js'
import { Bar, Line } from 'react-chartjs-2'
import Moment from 'moment'
import { useEffect, useState } from 'react'
import { BiLineChart, BiBarChartAlt } from 'react-icons/bi'
import classNames from 'classnames'
import { getPageViews } from '@onepager/stdlib'

// import Chart from 'react-apexcharts'

const ChartComponent = ({
  collectionId,
  onepagerId,
  type = 'timeseries',
  period = '7d',
  dateRange = [],
  user_id,
}) => {
  const [allViews, setAllViews] = useState({ labels: [], datasets: [] })

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Tooltip, Filler)

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {},
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          precision: 0,
          beginAtZero: true,
        },
      },
      y: {
        grid: {
          beginAtZero: true,
          display: false,
          min: 0,
        },
        ticks: {
          precision: 0,
          beginAtZero: true,
        },
      },
    },
  }

  // revisit - was calling it a million times a second
  // useEffect(async () => {
  //   const returnedViews = await getPageViews(user_id, collectionId, onepagerId, type, period, dateRange)
  //   if (returnedViews) {
  //     const labels = returnedViews.map((item, index) => {
  //       return Moment(item.date).format('MMM D')
  //     })

  //     setAllViews({
  //       labels,
  //       animation: {
  //         easing: 'easeInOutQuad',
  //         duration: 520,
  //       },
  //       datasets: [
  //         {
  //           data: returnedViews.map((item, index) => {
  //             return {
  //               x: Moment(item.date).format('MMM D'),
  //               y: parseInt(item.pageviews),
  //             }
  //           }),
  //           pointBackgroundColor: '#ffc800',
  //           borderWidth: 5,
  //           borderColor: '#ffc800',
  //         },
  //       ],
  //     })
  //   }
  // }, [period, dateRange, type])

  return (
    <div className="bg-white rounded border border-slate-200">
      {allViews && (
        <div className="p-6" style={{ height: '300px' }}>
          <Line options={options} data={allViews} />
        </div>
      )}
    </div>
  )
}

export default ChartComponent
