import axios from "axios";
import { supabase } from "../supabase";

// API Endpoint
export async function apiQuery(key, params) {
	const user = supabase.auth.user();

	const { data, error } = await axios.post(`/api/${key}`, {
		...params,
		user: user?.id,
		user_email: user?.email,
	});

	if (error) {
		createError("ApiQuery Error", "apiQuery", key);
	}

	return data;
}

// SUPABASE getAll - list
export async function getAll(model, order, range) {
	let { data, error } = await supabase
		.from(model)
		.select("*")
		.order(order.column, { ascending: order.order == "asc" ? true : false })
		.range(range.from, range.to);

	if (error) {
		createError(error, "getAll", key);
	}

	return data;
}

// SUPABASE getById - Single
export async function getById(model, id) {
	let { data, error } = await supabase
		.from(model)
		.select("*")
		.eq("id", id)
		.single();

	if (error) {
		createError("Not Found ?", "getById", { model, id });
	}

	return data;
}

// SUPABASE - create
export async function create(model, params) {
	let { data, error } = await supabase.from(model).insert(params).single();

	if (error) {
		createError("Couldn't create", "create", { model, params });
	}

	return data;
}

// SUPABASE  - updateById
export async function updateById(model, id, params) {
	let { data, error } = await supabase
		.from(model)
		.update(params)
		.eq("id", id)
		.single();

	if (error) {
		createError(error, "updateById", { model, id });
	}

	return data;
}

// SUPABASE - updateById
export async function deleteById(model, id) {
	let { data, error } = await supabase.from(model).delete().eq("id", id);

	if (error) {
		createError("Couldn't delete", "deleteById", { model, params });
	}

	return data;
}

// util function
function parseParams(params) {
	// create updata params
	let updateParams = {};

	// remove all keys starting with _
	for (let [key, value] of Object.entries(params)) {
		if (!key.startsWith("_")) {
			updateParams[key] = value;
		}
	}

	return updateParams;
}

function createError(error, fn, params) {
	const newErr = new Error(`${fn} - ${JSON.stringify(params)}`);
	newErr.info = error;
	newErr.status = 404;
	throw newErr;
}
