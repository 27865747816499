import Classnames from 'classnames'
import { getPageViews } from '@onepager/stdlib'
import { useEffect, useState } from 'react'
import Moment from 'moment'

const Stat = ({
  label,
  icon,
  iconTheme = 'gray',
  collectionId,
  onepagerId,
  type = 'aggregate',
  period = '7d',
  dateRange = [],
  user_id,
  checkType,
  value,
}) => {
  const [data, setData] = useState(null)

  useEffect(async () => {
    if (checkType !== 'custom') {
      const pageviews = await getPageViews(user_id, collectionId, onepagerId, type, period, dateRange)
      setData(pageviews)
    }
  }, [period, dateRange])

  function showDateRange() {
    let date_range = ''
    switch (period) {
      case '7d':
        date_range = 'Last 7 Days'
        break

      case '30d':
        date_range = 'Last 30 Days'
        break
      case '6mo':
        date_range = 'Last 6 Months'
        break
      case '12mo':
        date_range = 'Last Year'
        break
      case 'custom':
        date_range = `${Moment(dateRange[0]).format('MMM D')} - ${Moment(dateRange[1]).format('MMM D')}`
        break
    }
    return date_range
  }

  return (
    <div className="bg-white rounded border border-slate-200 px-4 py-4 relative">
      <h1 className="text-5xl font-extrabold">{checkType === 'custom' ? value : data}</h1>
      <h4 className="font-medium text-xl text-slate-600 flex items-center justify-left">
        {icon && (
          <div
            className={Classnames('p-1 text-sm rounded-full mr-2', {
              'text-slate-500 bg-slate-50': iconTheme === 'gray',
              'text-slate-400 bg-white': iconTheme === 'white',
              'text-red-500 bg-red-50': iconTheme === 'red',
              'text-blue-500 bg-blue-50': iconTheme === 'blue',
              'text-orange-500 bg-orange-50': iconTheme === 'orange',
              'text-indigo-500 bg-indigo-50': iconTheme === 'indigo',
            })}
          >
            {icon}
          </div>
        )}
        <span>{label}</span>
      </h4>
    </div>
  )
}

export default Stat
