import useSWR from "swr";
import { getAll } from "./index";

export function useDataList(key, options) {
	const { data, error, mutate } = useSWR(key, (key) =>
		getAll(
			key,
			{ column: options.order.column, order: options.order.sort },
			getPagination(options.pagination.index, options.pagination.limit)
		)
	);

	return {
		data: data,
		isLoading: !error && !data,
		isError: error,
		invalidate: mutate,
	};
}

// todo - improve - first go doesn't change
const getPagination = (page, size) => {
	const limit = size ? +size : 3;
	const from = page ? page * limit : 0;
	const to = page ? from + size - 1 : size - 1;

	return { from, to };
};
