import { HiSwitchVertical } from 'react-icons/hi'
import Link from 'next/link'
import { useState } from 'react'

const PageSwitcher = ({ links, title, type }) => {
  const [active, setActive] = useState(false)
  return (
    <div className=" w-full">
      <div
        className="flex items-center justify-between p-4 rounded shadow-sm border border-slate-200 cursor-pointer hover:bg-slate-50"
        onClick={() => {
          setActive(!active)
        }}
      >
        <div className="font-bold text-xl truncate">{title}</div>
        <span className="bg-slate-50 rounded-sm py-1 px-1 font-medium text-slate-500">
          <HiSwitchVertical />
        </span>
      </div>
      {active && (
        <div
          className="absolute"
          style={{
            top: '74px',
            left: '20px',
            height: 'auto',
            maxHeight: '400px',
            width: '342px',
            zIndex: 999,
          }}
        >
          <div className="p-4 bg-white shadow-xl border rounded  w-full space-y-2 overflow-x-hidden">
            {links.map((link, index) => (
              <Link key={index} href={`/${type}/${link.id}`}>
                <span
                  className="block px-4 py-3 font-medium text-slate-700 hover:bg-slate-50 cursor-pointer rounded border border-slate-200"
                  onClick={() => {
                    setActive(false)
                  }}
                >
                  {link.name || link.title}
                </span>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default PageSwitcher
