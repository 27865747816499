import * as Sentry from '@sentry/nextjs'

import { useState, useEffect } from 'react'
import { supabase } from '@onepager/stdlib'

import Login from 'components/Login'

const AuthWrapper = ({ children }) => {
  const [session, setSession] = useState(null)

  useEffect(() => {
    const s = supabase.auth.session()
    setSession(s)

    if (s?.user?.id) {
      // set user to sentry
      Sentry.setUser({ id: s.user.id })
    }

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])

  if (session) {
    return <div>{children}</div>
  } else {
    return <Login />
  }
}

export default AuthWrapper
