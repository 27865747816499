import Head from 'next/head'

import { MantineProvider } from '@mantine/core'
import { SWRConfig } from 'swr'
import { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'

import '../styles/tailwind.css'

import { Navigation, Footer } from '@onepager/ui'

// error notifier
const errorNotify = msg => toast.error(msg)

import AuthWrapper from 'components/AuthWrapper'

import LayoutShell from 'components/layout/LayoutShell'

import MobileSidebar from 'components/layout/MobileSidebar'
import Sidebar from 'components/layout/Sidebar'

import { Provider } from 'react-supabase'
import { supabase } from '@onepager/stdlib'

export default function App(props) {
  const { Component, pageProps } = props
  const [hasLayout, setLayout] = useState(true)

  useEffect(() => {
    if (props.router.route.includes('/builder/[id]')) {
      setLayout(false)
    } else {
      setLayout(true)
    }
  }, [props.router.route])

  return (
    <div className="bg-x  h-full">
      <Head>
        <title>Onepager</title>
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>

      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          colorScheme: 'light',
          fontFamily: '"CeraPro", "Helvetica Neue", Helvetica, Arial, sans-serif',
          fontFamilyMonospace: 'Monaco, Courier, monospace',
        }}
        className="bg-background"
      >
        <Provider value={supabase}>
          <SWRConfig
            value={{
              revalidateOnFocus: false,
              shouldRetryOnError: false,
              onError: (error, key) => {
                console.log('Log Error - top level!', error)
                errorNotify(error.info ? error.info : 'Oopps! Something Went Wrong!')

                if (error.status !== 403 && error.status !== 404) {
                  // We can send the error to Sentry,
                }
              },
            }}
          >
            <AuthWrapper>
              <div className="h-full overflow-hidden">
                {hasLayout && (
                  <LayoutShell>
                    {/* <MobileSidebar /> */}
                    {/* <Sidebar /> */}

                    {/* <Navigation /> */}
                    <Component {...pageProps} />
                    {/* <Footer /> */}
                  </LayoutShell>
                )}

                {!hasLayout && <Component {...pageProps} />}
              </div>
            </AuthWrapper>
          </SWRConfig>
        </Provider>

        <Toaster />
      </MantineProvider>
    </div>
  )
}
