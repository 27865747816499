import { useState } from 'react'

import { supabase } from '@onepager/stdlib'
import { Form, Input, Button } from '@onepager/ui'

import { useForm } from '@mantine/hooks'

const LoginComponent = () => {
  const [error, setError] = useState(false)

  const form = useForm({
    initialValues: {
      email: '',
    },

    validate: {
      email: value => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  return (
    <div className="p-20">
      <div className="">Login</div>

      <pre className="">{JSON.stringify(error?.message, null, 2)}</pre>

      <form
        onSubmit={form.onSubmit(async values => {
          console.log(values)

          let { error } = await supabase.auth.signIn({
            email: values.email,
          })

          if (error) {
            setError(error)
          }
          alert('go check email!')
        })}
      >
        <input name="email" {...form.getInputProps('email')} label="Email Address" placeholder="email..." />

        <button type="submit">Login</button>
      </form>

      {/* <Form
        className="space-y-2"
        values={{ email: '' }}
        rules={{
          email: value => value.trim().length >= 1,
        }}
        errors={{
          email: 'Need email',
        }}
        onSubmit={async (f, v) => {
          console.log(f, v)

          let { error } = await supabase.auth.signIn({
            email: v.email,
          })

          if (error) {
            setError(error)
          }
          alert('go check email!')
        }}
      >

        <input name="email" label="Email Address" placeholder="email..." />

        <button type="submit">Login</button>
      </Form> */}
    </div>
  )
}

export default LoginComponent
