import { ColorInput } from '@mantine/core'
import FormWrapper from './FormWrapper'

const UIColorPickerLabel = ({ form, title, desc, placeholder, formProp, icon, layout, size = 'lg' }) => {
  return (
    <FormWrapper
      title={title}
      desc={desc}
      layout={layout}
      input={
        <ColorInput
          required
          icon={icon}
          size={size}
          placeholder={placeholder}
          {...form.getInputProps(formProp)}
        />
      }
    />
  )
}

export default UIColorPickerLabel
