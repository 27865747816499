import React from 'react'
import { useForm } from '@mantine/hooks'

const UIForm = ({ children, values, rules, errors, onSubmit, className }) => {
  const form = useForm({
    initialValues: values,
    validationRules: rules,
    errorMessages: errors,
  })

  let elements = React.Children.toArray(children)
  let renderChildren = []

  for (let index = 0; index < elements.length; index++) {
    renderChildren.push([React.cloneElement(elements[index], { form: form })])
  }

  return (
    <form
      onSubmit={form.onSubmit(values => onSubmit(form, values))}
      className={className}
    >
      {renderChildren}
    </form>
  )
}

export default UIForm
