import { TextInput } from '@mantine/core'
import FormWrapper from './FormWrapper'

const Input = ({ form, formProp, title, desc, placeholder, icon, layout, size = 'lg' }) => {
  return (
    <FormWrapper
      title={title}
      desc={desc}
      layout={layout}
      input={
        <TextInput
          icon={icon}
          size={size}
          placeholder={placeholder}
          {...form.getInputProps(formProp)}
          onBlur={() => form.validateField(formProp)}
          classNames={{
            input: 'shadow-inner border border-slate-300 rounded',
          }}
        />
      }
    />
  )
}

export default Input
