import { Button } from '@onepager/ui'
import { useRouter } from 'next/router'

const Pagination = props => {
  const router = useRouter()
  const total = roundUp(parseInt(props.count) / parseInt(props.size))

  function roundUp(number) {
    const decimal = Math.pow(10, 0)
    return Math.ceil(number * decimal) / decimal
  }

  return (
    <div className="flex items-center space-x-2">
      {props.fetching && <span className="text-slate-400 font-medium">Loading...</span>}
      {!props.fetching && (
        <>
          <div className="text-slate-400">
            <span>{parseInt(props.page) + 1}</span> / <span>{total}</span>
          </div>
          {props.page > 0 && (
            <Button
              size="sm"
              onClick={() => {
                router.push({
                  pathname: props.path,
                  query: {
                    page: parseInt(props.page) - 1,
                  },
                })
              }}
            >
              Prev
            </Button>
          )}

          {props.page <= 0 && (
            <Button size="sm" theme="disabled">
              Prev
            </Button>
          )}

          {parseInt(props.page) < parseInt(total - 1) && (
            <Button
              size="sm"
              onClick={() => {
                router.push({
                  pathname: props.path,
                  query: {
                    page: parseInt(props.page) + 1,
                  },
                })
              }}
            >
              Next
            </Button>
          )}

          {parseInt(props.page) >= parseInt(total - 1) && (
            <Button size="sm" theme="disabled">
              Next
            </Button>
          )}
        </>
      )}
    </div>
  )
}

export default Pagination
