import styles from './SubNav.module.css'
import { css } from '@onepager/stdlib'

const SubNavigation = props => {
  return (
    <div className={css(styles.wrapper, props.classWrapper)}>
      <div className={styles.left}>{props.left}</div>
      <div className={styles.left}>{props.right}</div>
    </div>
  )
}

export default SubNavigation
