import {
  LockOpenIcon,
  MailIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UserIcon,
  TemplateIcon,
  XIcon,
} from '@heroicons/react/outline'

import classNames from 'classnames'
import { useRouter } from 'next/router'

const user = {
  name: 'Emily Selman',
  email: 'emily.selman@example.com',
  imageUrl: 'https://cdn-icons-png.flaticon.com/128/149/149071.png',
}

const navigation = [
  { name: 'Home', href: '/', icon: HomeIcon },
  { name: 'Onepagers', href: '/onepagers', icon: TemplateIcon, color: '#ffc900' },
  // { name: 'Lead Magnets', href: '/leadmagnets', icon: MailIcon, color: '#14b8a6' },
  { name: 'Memberships', href: '/memberships', icon: LockOpenIcon, color: '#8b5cf6' },
  { name: 'Coaching', href: '/coaching', icon: UserIcon, color: '#ec4899' },
]
const Sidebar = () => {
  const router = useRouter()

  return (
    <div className="hidden lg:flex lg:flex-shrink-0 h-screen sticky z-10 top-0">
      <div className="flex flex-col w-20">
        <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
          <div className="flex-1">
            <div className="bg-gray-900 h-20 flex items-center justify-center">
              <img
                className="h-8 w-auto"
                src="https://cdn.filestackcontent.com/jW9hY5L5RAWWpn2rGhw9"
                alt="Workflow"
              />
            </div>
            <nav aria-label="Sidebar" className="py-6 flex flex-col items-center space-y-3">
              {navigation.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    'group flex items-center p-4 rounded-lg text-white hover:bg-gray-900',
                    {
                      'bg-gray-900':
                        (router.pathname === '/' && item.href === '/') ||
                        (router.pathname !== '/' && item.href !== '/' && router.pathname.includes(item.href)),
                    }
                  )}
                >
                  <div className=" relative">
                    <span
                      className="hidden group-hover:block bg-gray-900 p-4 absolute left-6 rounded-r font-medium"
                      style={{ top: '-16px', height: '56px', width: 'auto' }}
                    >
                      {item.name}
                    </span>
                    <item.icon style={{ color: item.color }} className="h-6 w-6" aria-hidden="true" />
                  </div>
                </a>
              ))}
            </nav>
          </div>
          <div className="flex-shrink-0 flex pb-5">
            <a href="/account" className="flex-shrink-0 w-full">
              <img className="block mx-auto h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
              <div className="sr-only">
                <p>{user.name}</p>
                <p>Account settings</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
