const FormWrapper = ({ input, title, desc, layout }) => {
  return (
    <>
      {(!layout || layout === 'horizontal') && (
        <div className="flex lg:space-x-2 pb-0">
          <div className="w-3/6 pr-0 lg:pr-12">
            {title && <h4 className="text-xl font-medium text-slate-800">{title}</h4>}
            {desc && <p className="text-slate-400 text-sm leading-relaxed">{desc}</p>}
          </div>
          <div className="mt-2 lg:mt-0 w-4/6">{input}</div>
        </div>
      )}

      {layout === 'vertical' && (
        <div className="border-b border-slate-100">
          {title && <h4 className="text-xl font-medium text-slate-800">{title}</h4>}
          {desc && <p className="text-slate-400 leading-relaxed">{desc}</p>}
          <div className="mt-2">{input}</div>
        </div>
      )}
    </>
  )
}

export default FormWrapper
