import styles from './Table.module.css'

const Table = ({ thead, tbody }) => {
  return (
    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className="overflow-hidden ring-1 ring-black ring-opacity-5 md:rounded">
          <table className="min-w-full divide-y divide-slate-100">
            <thead className="bg-white">
              <tr>
                {thead.map((th, i) => (
                  <th key={i} scope="col" className="text-left">
                    {th != 'Edit' && (
                      <div className="py-3 px-3 font-medium uppercase tracking-wide text-slate-500 sm:pl-6">
                        {th}
                      </div>
                    )}

                    {th == 'Edit' && (
                      <div className="relative py-3 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-slate-100 bg-white">
              {tbody.map((tr, i) => (
                <tr key={i}>
                  {tr.map((td, index) => (
                    <td key={index}>
                      {thead[index] != 'Edit' && (
                        <div className="whitespace-nowrap py-4 pl-4 pr-3 text-slate-900 sm:pl-6">{td}</div>
                      )}

                      {thead[index] == 'Edit' && (
                        <div className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right font-medium sm:pr-6">
                          {td}
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Table
