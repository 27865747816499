import { useState } from "react";
import useSWR from "swr";
import { getById, updateById, deleteById } from "./index";

export function useDataById(key) {
	const [isMutating, toggleMutating] = useState(false);

	const { data, error, mutate } = useSWR(key, getById);

	async function updateData(params) {
		try {
			toggleMutating(true);
			await mutate(updateById(key[0], key[1], params), false);
			toggleMutating(false);
		} catch (error) {
			toggleMutating(false);
		}
	}

	async function deleteData() {
		await mutate(deleteById(key[0], key[1]), true);
		return true;
	}

	return {
		data: data,
		isLoading: !error && !data,
		isMutating: isMutating,
		isError: error,
		update: updateData,
		delete: deleteData,
		invalidate: mutate,
	};
}
