import { Button } from '@mantine/core'
import { css } from '@onepager/stdlib'
import { useState } from 'react'
const UIButton = ({ children, type, form, loading, onClick, theme, size, compact, className, icon }) => {
  function getTheme(color) {
    let theme = ''
    switch (color) {
      case 'blue':
        theme = 'bg-blue-400 border border-blue-500 hover:bg-blue-500'
        break
      case 'red':
        theme = 'bg-red-500 hover:bg-red-700'
        break
      case 'green':
        theme = 'bg-green-500 border border-green-600 hover:bg-green-700'
        break
      case 'dark':
        theme = 'bg-slate-800 border border-slate-900 hover:bg-slate-800'
        break
      case 'disabled':
        theme =
          'bg-white border border-slate-300 text-slate-400 hover:bg-slate-50 opacity-50 cursor-not-allowed'
        break

      default:
        theme = 'bg-white border border-slate-300 text-slate-700 hover:bg-slate-50'
        break
    }
    return theme
  }

  return (
    <Button
      onClick={onClick}
      className={css(getTheme(theme), className)}
      size={size}
      loading={loading}
      type={type}
      compact={compact}
    >
      {icon}
      {children}
    </Button>
  )
}

export default UIButton
