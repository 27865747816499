import { useState } from 'react'
import { DateRangePicker } from '@mantine/dates'
import { Select } from '@mantine/core'
import Moment from 'moment'

const PageViewPicker = ({ period, dateRange, updatePeriod, updateDateRange, className }) => {
  const [newDateRange, setNewDateRange] = useState(dateRange)
  const [timePeriod, setTimePeriod] = useState(period)

  const options = [
    { value: '7d', label: 'Last 7 Days' },
    { value: '30d', label: 'Last 30 Days' },
    { value: '6mo', label: 'Last 6 Months' },
    { value: '12mo', label: 'Last Year' },
  ]

  const changePeriod = value => {
    setTimePeriod(value)
    updatePeriod(value)
  }

  const changeDateRange = value => {
    setTimePeriod('custom')
    const data = []
    // console.log(value)
    for (let index = 0; index < value.length; index++) {
      const item = value[index]
      // console.log({ item, valid: Moment(item).isValid() })
      if (Moment(item).isValid()) {
        data.push(Moment(item).format('yyyy-MM-DD'))
      }
    }
    setNewDateRange(data)
    updateDateRange(data)
  }

  return (
    <div className={`flex items-center space-x-2 ${className}`}>
      <Select value={timePeriod} placeholder="Quick Select" onChange={changePeriod} data={options} />
      <DateRangePicker placeholder="Pick a Date Range" value={newDateRange} onChange={changeDateRange} />
    </div>
  )
}

export default PageViewPicker
