import styles from './Banner.module.css'
import { css } from '@onepager/stdlib'

const Banner = ({ children, padding, classInner, color }) => {
  function getTheme(theme) {
    switch (theme) {
      case 'purple':
        return styles.purple
      case 'blue':
        return styles.blue
      case 'dark':
        return styles.blue
      default:
        return styles.light
    }
  }
  function getPadding(value) {
    switch (value) {
      case 'large':
        return styles.padding_large
      case 'small':
        return styles.padding_small
      default:
        return ''
    }
  }
  return (
    <div className={css(styles.wrapper, getTheme(color), getPadding(padding))}>
      <div className={css(styles.inner_wrapper, classInner, 'w-full')}>{children}</div>
    </div>
  )
}

export default Banner
