import { Select } from '@mantine/core'
import FormWrapper from './FormWrapper'

const UISelectLabel = ({ form, title, desc, placeholder, formProp, icon, layout, size = 'lg', data }) => {
  return (
    <FormWrapper
      title={title}
      desc={desc}
      layout={layout}
      input={
        <Select
          required
          icon={icon}
          size={size}
          placeholder={placeholder}
          {...form.getInputProps(formProp)}
          data={data}
          classNames={{
            input: 'shadow-inner border border-slate-300 rounded',
          }}
        />
      }
    />
  )
}

export default UISelectLabel
