import { Tabs } from '@mantine/core'

const TabNav = ({ tabs }) => {
  return (
    <Tabs
      variant="pills"
      classNames={{
        tabLabel: 'font-medium p-2 px-1 text-lg flex',
        tabInner: 'flex',
        tabsListWrapper: 'border-b border-slate-200 bg-slate-50',
        tabActive: 'rounded-none border-none m-0 font-bold',
        tabsList: 'border-b border-slate-200',
      }}
      styles={{
        tabActive: {
          color: '#0ea5e9 !important',
          borderColor: '#0ea5e9 !important',
          backgroundColor: 'rgb(248,250,252) !important',
          borderBottom: '2px solid #0ea5e9 !important',
        },
        tabsList: {
          borderBottom: 'none',
        },
      }}
    >
      {tabs.map((tab, index) => (
        <Tabs.Tab key={index} label={tab.label}>
          <div className="px-12 py-8">{tab.content}</div>
        </Tabs.Tab>
      ))}
    </Tabs>
  )
}

export default TabNav
