import styles from './Missing.module.css'
import { css } from '@onepager/stdlib'
import Link from 'next/link'

const SubNavigation = props => {
  return (
    <div className={css(styles.container, 'container')}>
      {props.text && <span>{props.text}</span>}
      {props.linkText && (
        <Link href={props.link}>
          <span className={css(styles.link)}>{props.linkText}</span>
        </Link>
      )}
    </div>
  )
}

export default SubNavigation
